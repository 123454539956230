
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import useContactsSegmentsOpenModal from '@/compositions/contacts/useContactsSegmentsOpenModal'

export default defineComponent({
  components: {
    TmTableActionButton,
    TmDropdown,
    TmDropdownItem,
  },
  props: {
    id: {
      type: String,
    },
    segmentName: {
      type: String,
    },
    tags: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup() {
    const { openEditSegmentsModal, openDuplicateSegmentsModal, openDeleteSegmentModal } = useContactsSegmentsOpenModal()

    return {
      openEditSegmentsModal,
      openDuplicateSegmentsModal,
      openDeleteSegmentModal,
    }
  },
})
