
import { computed, defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import ContactsSegmentsFilter from '@/components/pages/contacts/segments/ContactsSegmentsFilter.vue'
import ContactsSegmentsTable from '@/components/pages/contacts/segments/ContactsSegmentsTable.vue'
import ContactsSegmentsEmpty from '@/components/pages/contacts/segments/ContactsSegmentsEmpty.vue'
import { getTableData } from '@/services/tableService'
import { formatDate } from '@/services/dateTimeService'
import type { TableHeaders } from '@/definitions/shared/types'
import { useModes } from '@/compositions/modes'
import type { ContactSegment } from '@/definitions/contacts/segments/types'
import { segmentsFilters } from '@/definitions/shared/filters/filtersList/data'
import { useFilters } from '@/compositions/filters'

export default defineComponent({
  components: {
    ContactsSegmentsEmpty,
    ContactsSegmentsTable,
    ContactsSegmentsFilter,
    PageContent,
  },
  props: {
    title: {
      type: String,
    },
  },
  setup() {
    const { isEmptyMode } = useModes()

    const search = ref('')
    const { filters, isDefault, reset } = useFilters(segmentsFilters)
    const selected = ref<ContactSegment[]>([])
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Segment name', value: 'segment-name', width: '23%' },
      { text: 'Contacts', value: 'contacts', width: '10%' },
      { text: 'Tags', value: 'tags', hideSortable: true },
      { text: 'Created by', value: 'created-by', width: '16%' },
      { text: 'Last updated', value: 'lastUpdated', format: (val: string) => formatDate(val), width: '12%' },
      { text: 'Date created', value: 'dateCreated', format: (val: string) => formatDate(val), width: '12%' },
    ])
    const tableItems = ref<ContactSegment[]>(getTableData('contactsSegments'))
    const filteredTableItems = computed<ContactSegment[]>(() => {
      return tableItems.value.filter((item) => item.segmentName.toLowerCase().includes(search.value.toLowerCase()))
    })

    return {
      isEmptyMode,
      filters,
      isDefault,
      reset,
      search,
      selected,
      tableHeaders,
      tableItems,
      filteredTableItems,
    }
  },
})
