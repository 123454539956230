
import { defineComponent } from 'vue'
import TmEmptyState from '@/components/shared/states/TmEmptyState.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  name: 'ContactsSegmentsEmpty',
  components: {
    TmButton,
    TmEmptyState,
  },
  setup() {
    const { openModal } = useModals()

    return {
      openModal,
    }
  },
})
