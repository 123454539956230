import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_highlight = _resolveComponent("highlight")!
  const _component_tm_ellipsis = _resolveComponent("tm-ellipsis")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_tm_badges = _resolveComponent("tm-badges")!
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_tm_person = _resolveComponent("tm-person")!
  const _component_contacts_segments_dropdown = _resolveComponent("contacts-segments-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, null, {
    "body-cell-segment-name": _withCtx((props) => [
      _createVNode(_component_router_link, {
        to: { name: 'base.contacts.segments.details', params: { id: props.row.id } },
        class: "d-flex align-center font-color-inherit font-weight-medium blue-on-hover"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_avatar, {
            "custom-icon": "pie_chart",
            "custom-icon-size": "16px",
            color: "gray",
            class: "mr-2",
            size: "small"
          }),
          _createVNode(_component_tm_ellipsis, null, {
            default: _withCtx(() => [
              _createVNode(_component_highlight, {
                text: props.row.segmentName,
                search: _ctx.search
              }, null, 8, ["text", "search"])
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1032, ["to"])
    ]),
    "body-cell-contacts": _withCtx((props) => [
      _createTextVNode(_toDisplayString(props.row.contacts), 1)
    ]),
    "body-cell-tags": _withCtx((props) => [
      (props.row.tags.length)
        ? (_openBlock(), _createBlock(_component_tm_badges, {
            key: 0,
            items: props.row.tags,
            size: "large",
            static: "",
            "visible-items": 1
          }, null, 8, ["items"]))
        : (_openBlock(), _createElementBlock("span", _hoisted_1, "-"))
    ]),
    "body-cell-visibility": _withCtx((props) => [
      _createVNode(_component_tm_status, _normalizeProps(_guardReactiveProps(props.row.visibility)), null, 16)
    ]),
    "body-cell-type": _withCtx((props) => [
      _createVNode(_component_tm_status, _normalizeProps(_guardReactiveProps(props.row.type)), null, 16)
    ]),
    "body-cell-created-by": _withCtx((props) => [
      (_openBlock(), _createBlock(_component_router_link, {
        key: props.row.createdBy.fullName,
        to: { name: 'base.contacts.contactDetails', params: { id: '1' } },
        class: "d-flex align-center font-color-inherit font-weight-normal blue-on-hover w100pr"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_person, {
            name: props.row.createdBy.fullName,
            "avatar-url": props.row.createdBy.avatar,
            "avatar-color": props.row.createdBy.avatarColor,
            search: _ctx.search,
            "avatar-size": "small"
          }, null, 8, ["name", "avatar-url", "avatar-color", "search"])
        ]),
        _: 2
      }, 1032, ["to"]))
    ]),
    "body-cell-actions": _withCtx((props) => [
      _createVNode(_component_contacts_segments_dropdown, {
        id: props.row.id,
        "segment-name": props.row.segmentName,
        tags: props.row.tags
      }, null, 8, ["id", "segment-name", "tags"])
    ]),
    _: 1
  }))
}